import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs';
import { Pages } from '../app.routes';

export const registrazioneGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const auth = inject(AuthService);
  const router = inject(Router);

  let isWebView = true;
  let token = auth.getAuthorizationToken();

  const { token: routeToken } = state.root.queryParams;

  if (routeToken) {
    auth.setAuthorizationToken(routeToken);
    token = routeToken;
    isWebView = false;
  }

  console.log(`.registrazioneGuard - token`, token);

  return userService.user$
    .pipe(
      map(user => {
        console.log(`registrazioneGuard.user.status`, user?.status)

        if (!isWebView) {
          return true
        }

        switch (user.status) {
          case "none": {
            return router.createUrlTree([Pages.home]);
          }
          case "request": {
            return router.createUrlTree([Pages.home]);
          }
          case "success": {
            if (!token) {
              return true;
            }
            return router.createUrlTree([Pages.concorso]);
          }
          case "error": {
            return router.createUrlTree([Pages.home]);
          }
          default: {
            return router.createUrlTree([Pages.home]);
          }
        }
      }),
    );
};
