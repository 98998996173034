// import { slug } from '../types/giocatore';

export const tags = ["IT_Custom_18", "IT_Custom_19", "IT_Custom_20", "IT_Custom_15",];
export const tagsMap = new Map<string, string>([
  // ['spicy_beginner', tags[0]],
  // ['spicy_special', tags[1]],
  // ['spicy_pro', tags[2]],
  // ['spicy_boss', tags[3]],
]);

