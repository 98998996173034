import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { map, of, retry, shareReplay, switchMap } from 'rxjs';
import { Pages } from '../app.routes';
import { GameService } from '../services/game.service';

export const gameGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const gameService = inject(GameService);
  const router = inject(Router);

  return userService.user$
    .pipe(
      map(user => {
        console.log(`gameGuard.user.status`, user?.status)
        switch (user.status) {
          case "none": {
            return router.createUrlTree([Pages.home])
          }
          case "request": {
            return router.createUrlTree([Pages.home])
          }
          case "success": {
            return true
          }
          case "error": {
            return router.createUrlTree([Pages.home])
          }
          default: {
            return router.createUrlTree([Pages.home])
          }
        }
      }),
      switchMap(result => {
        // Se ho un token verifico lo stato dell'utente
        if (typeof result === 'boolean' && result === true) {
          return gameService.getGameStatus()
            .pipe(
              map(gameStatus => {
                if (gameStatus) {
                  return true;
                }
                return router.createUrlTree([Pages.home])
              }),
              retry(2),
              shareReplay(1),
            )
        }

        // Se non ho i dati dell'utente lo rimando in home
        return of(result)
      })
    );
}
