import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import { Pages } from '../app.routes';
import { GameService } from '../services/game.service';

export const gameResultGuard: CanActivateFn = (route, state) => {

  const gameService = inject(GameService);
  const router = inject(Router);

  return gameService.gameInstance$
    .pipe(
      map(({ time }) => {
        console.log(`gameResultGuard - time`, time);
        if (time > 0) {
          return true
        }
        return router.createUrlTree([Pages.gameWeeks])
      })
    )

};
