import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { GameService } from '../services/game.service';
import { map } from 'rxjs';
import { Pages } from '../app.routes';

export const gamePlayGuard: CanActivateFn = (route, state) => {
  const gameService = inject(GameService);
  const router = inject(Router);

  return gameService.gameInstance$
    .pipe(
      map(({ week }) => {
        if (week) {
          return true;
        }

        return router.createUrlTree([Pages.gameWeeks])
      })
    )

};
