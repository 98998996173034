import { inject, Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageKey } from '../../shared/enums/storage.key';
import { Jwt, SignUpData, StatusResponseData } from '../../types/signup';
import { endpointURL } from '../../shared/endpoint';
import { catchError, EMPTY, of, shareReplay, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getHash } from '../../shared/hash';
import { UserService } from './user.service';
import { status$ } from '../../shared/status';
import { AlertService } from './alert.service';
import { UserGame } from '../../types/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private http = inject(HttpClient);
  private userService = inject(UserService);
  private storageService = inject(StorageService);
  private alertService = inject(AlertService);

  constructor() {
  }

  getAuthorizationToken(): string | null {
    return this.storageService.get<string>(StorageKey.USER_TOKEN);
  }

  setAuthorizationToken(token: string) {
    this.storageService.set(StorageKey.USER_TOKEN, token);
  }

  removeAuthorizationToken() {
    this.storageService.remove(StorageKey.USER_TOKEN);
  }

  me() {
    this.alertService.showLoading();

    const url = `${endpointURL}auth/me`;

    return this.http.post<{ data: StatusResponseData }>(url, {})
      .pipe(
        // delay(3000),
        tap(response => {
          this.alertService.hideLoading();
          const { data } = response;
          status$.next(data);
        }),
        shareReplay(1),
      );
  }

  login(body: SignUpData | UserGame, isGame = false) {
    this.alertService.showLoading();

    const url = `${endpointURL}auth/login`;

    const user = this.userService.user$.getValue()?.user
    const { email, firstname, lastname } = user ?? {};

    const hash = getHash(email);

    body = { ...body, nome: firstname, cognome: lastname };

    return this.http.post<{ data: StatusResponseData; jwt: Jwt }>(url, { ...body, hash })
      .pipe(
        tap(response => {
          this.alertService.hideLoading();

          const { data, jwt } = response ?? {};
          status$.next(data);
          this.setAuthorizationToken(jwt.access_token);
        }),
        catchError(e => {
          this.alertService.error(e);
          return EMPTY
        })
      );
  }

  resetUser(email: string) {
    this.alertService.showLoading();

    const url = `${endpointURL}auth/me`;

    return this.http.delete<{ data: any }>(url)
      .pipe(
        // take(1),
        catchError(e => {
          this.alertService.error(e);
          return of(e.error.message)
        })
      )

  }
}
