import { Environment } from './environment.model';
import { EnvType } from '../shared/enums/env-type';

export const environment: Environment = {
  type: EnvType.STAGING,
  production: false,
  host: 'https://crispy-dev.logicworks.it',
  appURL: 'https://dg95h.app.goo.gl/7Ci9',
  endpointURL: `https://crispy-backend-dev.logicworks.it/api/`,
  regolamentoURL: 'https://www.mcdonalds.it/sites/default/files/html/documents/2024-02/co_mcd_crispysemprecomunque.pdf',
  isDebug: true,
}
