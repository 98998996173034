import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpointURL } from '../../shared/endpoint';
import { BehaviorSubject, catchError, filter, map, Observable, of, retry, shareReplay, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { status$ } from '../../shared/status';
import { WeekData } from '../../types/week-data';
import { StatusResponseData } from '../../types/signup';
import { GameInstance } from '../../types/game';
import { AlertService } from './alert.service';
import { Classifica } from '../../types/classifica';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  gameInstance$: BehaviorSubject<GameInstance> = new BehaviorSubject<GameInstance>({ week: undefined, time: 0 });

  private http = inject(HttpClient);
  private auth = inject(AuthService);
  private alertService = inject(AlertService);

  isGameAvailable$ = this.getGameDates()
    .pipe(
      filter(({ startDate, endDate }) => startDate !== -1),
      switchMap(({ startDate, endDate }) => {
        const now = new Date().getTime();
        const timeDiff = now - startDate;

        if (timeDiff <= 0) {
          console.log(`HomePage.endDate$ - timeDiff - Evento INIZA alle: ${new Date(startDate)}`)
          // Evento non ancora iniziato
          return of(false)
        }

        console.log(`HomePage.endDate$ - timeDiff - Evento IN CORSO alle: ${new Date(startDate)}`)
        // Evento in corso
        return of(true)

      })
    )

  constructor() {
  }

  getGameDates() {
    let random = `${Math.random() * 10000}`.replace('.', '');
    return this.http.get<{
      endDate: number,
      startDate: number
    }>(`./assets/json/date.json?v=${random}`);
  }

  getGameStatus() {

    console.log(`GameService.getGameStatus`,);
    return status$
      .pipe(
        switchMap(status => {
          const { game } = status ?? {};

          console.log(`GameService.getGameStatus - game`, game);
          if (!!game) {
            return of(game);
          }

          return this.auth.me()
            .pipe(
              map(response => {
                const { game } = response.data;
                console.log(`GameService.getGameStatus - authenticated`, game);
                return game;
              }),
              // Se c'è un errore non sono autenticato, quindi faccio la chiamata di login con la sola email + hash
              catchError(e => {
                  console.log(`gameGuard - user not authenticated, login...`)
                return this.auth.login({ hash: '' }, true)
                    .pipe(
                      tap(response => console.log(`gameGuard - user authenticated after login, goto game intro`)),
                      map(response => {
                        const { game } = response.data;
                        console.log(`GameService.getGameStatus - authenticated after login`, game);
                        return game;
                      }),
                      catchError(e => {
                        // this.errorService.error(e);
                        return of(undefined);
                      })
                    );
                }
              ),
              retry(2),
              shareReplay(1),
            )
        }),
        retry(2),
        shareReplay(1),
      )
  }

  getClassifiche(): Observable<Classifica[]> {

    const url = `${endpointURL}game/classifica`;

    return this.http.post<{ data: { week: Classifica[] } }>(url, {})
      .pipe(
        map(({ data }) => data?.week ?? []),
        tap(response => this.alertService.hideLoading()),
        catchError(e => {
          this.alertService.error(e);
          return of(e.error.message)
        })
      )
  }

  startGameInstance(week: WeekData) {
    this.gameInstance$.next({ week, time: 0 });
  }

  endGameInstance(time: number) {
    const { week } = this.gameInstance$.getValue();
    this.gameInstance$.next({ week, time });
  }

  giocata(tempo: number, fase_id: number) {
    this.alertService.showLoading();

    const url = `${endpointURL}game/giocata`;
    return this.http.post<{ data: StatusResponseData; }>(url, { tempo, fase_id })
      .pipe(
        // take(1),
        tap(response => {
          this.alertService.hideLoading();

          const { data } = response ?? {};
          status$.next(data);
        }),
        catchError(e => {
          this.alertService.error(e);
          return of(e.error.message)
        })
      )
  }

}
