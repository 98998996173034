import { Routes } from '@angular/router';
import { gameGuard } from './guards/game.guard';
import { concorsoGuard } from './guards/concorso.guard';
import { gamePlayGuard } from './guards/game-play.guard';
import { gameResultGuard } from './guards/game-result.guard';
import { webViewGuard, webViewUserGuard } from './guards/webview.guard';
import { registrazioneGuard } from './guards/registrazione.guard';

export const Pages = {
  home: ``,
  share: `condividi`,
  concorso: `concorso`,
  carica: `carica`,
  registrazione: `registrazione`,
  tutorial: `tutorial`,
  shipping: `shipping`,
  complimenti: `complimenti`,
  informative: `informative`,
  //
  gameIntro: `gameIntro`,
  gamePlay: `gamePlay`,
  gameResult: `gameResult`,
  gameWeeks: `gameWeeks`,
  gameClassifica: `gameClassifica`,
} as const;

export type PagesType = keyof typeof Pages;

export const routes: Routes = [
  {
    path: Pages.home,
    canActivate: [webViewGuard],
    loadComponent: () => import(`./pages/home/home.component`).then(mod => mod.HomeComponent),
    title: '#CrispySempreEComunque'
  },

  // CONTEST

  {
    path: Pages.concorso,
    redirectTo: Pages.carica,
  },
  {
    path: Pages.carica,
    canActivate: [concorsoGuard],
    loadComponent: () => import(`./pages/carica/carica.component`).then(mod => mod.CaricaComponent),
    title: '#CrispySempreEComunque - Carica un contenuto'
  },
  {
    path: Pages.registrazione,
    canActivate: [webViewGuard, registrazioneGuard],
    loadComponent: () => import(`./pages/registrazione/registrazione.component`).then(mod => mod.RegistrazioneComponent),
    title: '#CrispySempreEComunque - Registrazione'
  },
  {
    path: Pages.tutorial,
    canActivate: [webViewGuard],
    loadComponent: () => import(`./pages/tutorial/tutorial.component`).then(mod => mod.TutorialComponent),
    title: '#CrispySempreEComunque - Come partecipare'
  },
  {
    path: Pages.shipping,
    canActivate: [webViewUserGuard],
    loadComponent: () => import(`./pages/shipping/shipping.component`).then(mod => mod.ShippingComponent),
    title: '#CrispySempreEComunque - Dati spedizione'
  },
  {
    path: Pages.complimenti,
    canActivate: [webViewUserGuard],
    loadComponent: () => import(`./pages/complimenti/complimenti.component`).then(mod => mod.ComplimentiComponent),
    title: '#CrispySempreEComunque - Complimenti'
  },
  {
    path: Pages.informative,
    loadComponent: () => import(`./pages/informative/informative.component`).then(mod => mod.InformativeComponent),
    title: '#CrispySempreEComunque - Informative'
  },


  // GAME

  {
    path: Pages.gameIntro,
    canActivate: [webViewGuard, gameGuard],
    loadComponent: () => import(`./pages/game/game-intro/game-intro.component`).then(mod => mod.GameIntroComponent),
    title: 'Crispy Game - Trova Crispy McBacon®'
  },
  {
    path: Pages.gameWeeks,
    canActivate: [webViewGuard, gameGuard],
    loadComponent: () => import(`./pages/game/game-weeks/game-weeks.component`).then(mod => mod.GameWeeksComponent),
    title: 'Crispy Game - I giochi'
  },
  {
    path: Pages.gamePlay,
    canActivate: [webViewGuard, gameGuard, gamePlayGuard],
    loadComponent: () => import(`./pages/game/game-play/game-play.component`).then(mod => mod.GamePlayComponent),
    title: 'Crispy Game - Trova Crispy McBacon®'
  },
  {
    path: Pages.gameResult,
    canActivate: [webViewGuard, gameResultGuard],
    loadComponent: () => import(`./pages/game/game-result/game-result.component`).then(mod => mod.GameResultComponent),
    title: 'Crispy Game - Risultato'
  },
  {
    path: Pages.gameClassifica,
    canActivate: [webViewGuard],
    loadComponent: () => import(`./pages/game/game-classifica/game-classifica.component`).then(mod => mod.GameClassificaComponent),
    title: 'Crispy Game - Classifica'
  },

  // FALLBACK

  {
    path: ``,
    redirectTo: Pages.home,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];
