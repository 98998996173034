import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { McdUserData, UserResult } from '../../types/user';
import { environment } from '../../environments/environment';
import { tags, tagsMap } from '../../shared/tags';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  readonly statusDefault: UserResult = { user: undefined, status: 'none', message: 'init' };
  // readonly statusDefault: UserResult = { user: { email: `test${Math.floor(Math.random() * 10000)}@logicweb.it` }, status: 'success', message: 'fake' };
  // readonly statusDefault: UserResult = { user: { email: `test+8@logicweb.it`, firstname: `Moreno`, lastname: `Tomasella` }, status: 'success', message: 'fake' };
  // readonly statusDefault: UserResult = {
  //   user: {
  //     email: `test+19@logicweb.it`,
  //     firstname: `Federico`,
  //     lastname: `Bianco`
  //   }, status: 'success', message: 'fake'
  // };
  // readonly statusDefault: UserResult = { user: { email: `moreno@logicweb.it`, firstname: `Moreno`, lastname: `Tomasella` }, status: 'success', message: '' };

  user$: BehaviorSubject<UserResult> = new BehaviorSubject<UserResult>(this.statusDefault);
  private _timeoutUnavailable = 0;

  log$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _log: string = '';

  private _logTimeout: number = 0;

  constructor(private zone: NgZone) {

    this.user$.next(this.statusDefault);

    if (this.statusDefault.message === 'fake') {
      return;
    }

    if (typeof mcd !== 'undefined' && !!mcd?.bridge) {
      // console.log(`Bridge ready ${!!mcd?.bridge}`);
      this.getUser('constructor');
    } else {
      document.addEventListener('mcdBridgeReady', (e) => this.getUser('event'));

      // if (environment.production) {
      this._timeoutUnavailable = window.setTimeout(() => {
        // console.log(`UserService.timeout - user unavailable`);
        this.user$.next({ user: undefined, status: 'unavailable', message: `No mcd` });
      }, 5000)
      // }
    }
  }

  getUser(from: string) {

    if (typeof mcd !== 'undefined' && mcd?.bridge) {
      const user = mcd?.bridge?.message('user'); //access to user bridge

      window.clearTimeout(this._timeoutUnavailable);

      if (!user) {
        window.setTimeout(() => this.zone.run(() => {
          this.user$.next({ user: undefined, status: 'error', message: `No user Bridge` });
        }), 0)
        return;
      }

      user.on('data', (data: McdUserData) => {
        const { email, firstname, lastname } = data ?? {};

        // this.log(JSON.stringify(data));
        // this.log(window.navigator.userAgent);

        window.setTimeout(() => this.zone.run(() => {
          // this.user$.next({ user: { email: `test1@logicweb.it` }, status: 'success', message: 'fake' });
          this.user$.next({ user: { email, firstname, lastname }, status: 'success', message: `from ${from} - ok` });
        }), 0)

        // this.user$.next({ user: { email }, status: 'success', message: `from ${from} - ok` });
      });

      user.on('error', (error: any) => {
        window.setTimeout(() => this.zone.run(() => {
          this.user$.next({ user: undefined, status: 'error', message: JSON.stringify(error) });
        }), 0)
      });

      user.on('done', () => {
        document.removeEventListener('mcdBridgeReady', () => this.getUser('event'));
        //No more event will be emitted
        console.log(`user done `);
      });

      this.user$.next({ user: undefined, status: 'request', message: `from ${from} - get user data` });

      // Richiama il prompt per effettuare la login o se già loggato fa lo stesso di getuser
      user.send({ "promptlogin": true }); //send command "promptlogin"
      return;
    }

    this.user$.next({ user: undefined, status: 'error', message: `No user Bridge` });
  }

  addRemoveTag(slug: string) {

    const tag = tagsMap.get(slug);

    if (!tag) {
      this.log(`No tag for slug ${slug}\n`);
      return;
    }

    this.removeTags(tag);

  }

  private addTag(tag: string) {
    if (typeof mcd !== 'undefined' && mcd?.bridge) {
      const user = mcd?.bridge?.message('user'); //access to user bridge
      user.on('data', (data: any) => {
        this.log(`add tags: data: ${JSON.stringify(data)}\n`);
      });

      user.on('error', (error: any) => {
        this.log(`add tags: error: ${JSON.stringify(error)}\n`);
      });

      user.on('done', () => {
        //no more event will be emitted
        this.log(`add tags: done\n`);

      });
      user.send({ "addTags": [tag] });
    }
  }

  private removeTags(tag: string) {
    if (typeof mcd !== 'undefined' && mcd?.bridge) {
      const user = mcd?.bridge?.message('user'); //access to user bridge
      user.on('data', (data: any) => {
        this.log(`remove tags: data: ${JSON.stringify(data)}\n`);
      });

      user.on('error', (error: any) => {
        this.log(`remove tags: error: ${JSON.stringify(error)}\n`);
      });

      user.on('done', () => {
        //no more event will be emitted
        this.log(`remove tags: done\n`);
        this.addTag(tag);
      });

      user.send({ "removeTags": tags });

    }
  }

  log(text: string) {
    const currentLog = this.log$.getValue();
    this._log = `${currentLog}\n${text}`;
    this.updateLog();
  }

  private updateLog() {
    if (environment.production) {
      return;
    }
    window.clearTimeout(this._logTimeout);
    this._logTimeout = window.setTimeout(() => this.zone.run(() => this.log$.next(this._log)), 0)
  }
}
