export type osType = 'Android' | 'iOS' | 'Windows Phone' | 'unknown';

export function getAndroidVersion(ua?: string) {
  ua = (ua || navigator.userAgent).toLowerCase();
  const match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
}

export function getAndroidVersionNumber() {
  const version = getAndroidVersion();
  if (!version) {
    return 0
  }
  return parseInt(version, 10);
}


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem(): osType {
  const { userAgent } = navigator;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
}

export function isWebview() {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  // @ts-ignore
  const standalone = navigator?.['standalone'];

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)

  return isWebview
}

// const isInBrowser = () => {
//   // Lo share non c'è nella webview di Android https://developer.mozilla.org/en-US/docs/web/api/navigator/share#browser_compatibility
//   if (!navigator.share) {
//     return false;
//   }
//
//   if (!) {
//     return false;
//   }
//
//   return true;
// }
//
// const os = getMobileOperatingSystem();
// if (isWebView && os === 'Android') {
//   const androidVersion = getAndroidVersionNumber();
//
//   if (androidVersion >= 13) {
//     const { host } = environment;
//     const url = `${host}/carica?openOutsideMcd=true&token=${token}`;
//     const a = document.createElement('a');
//     a.href = url
//     // a.target = '_blank';
//     a.click();
//
//     // window.open(url);
//     return router.createUrlTree([Pages.home]);
//   }
//
//   return true;
// }
