import { inject, Injectable, NgZone } from '@angular/core';

export type AlertMessage = { message: string, title?: string };

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  title = 'Attenzione!';
  message = '';

  private _dialog: HTMLDialogElement | undefined;
  private _loading: HTMLDialogElement | undefined;

  private _loadingTimeout = 0;

  private _ngZone: NgZone = inject(NgZone);

  initDialog(dialog: HTMLDialogElement) {
    this._dialog = dialog;
  }

  initLoading(dialog: HTMLDialogElement) {
    this._loading = dialog;
  }

  show(alert: AlertMessage) {
    this.title = alert?.title ?? 'Attenzione!';
    this.message = alert.message ?? '';

    this._dialog?.showModal();
  }

  showLoading() {
    window.clearTimeout(this._loadingTimeout);
    this._loadingTimeout = window.setTimeout(() => this._ngZone.run(() => this._loading?.showModal()), 800);
  }

  hideLoading() {
    window.clearTimeout(this._loadingTimeout);
    this._loading?.close()
  }

  error(error: any) {
    this.hideLoading();

    const { code, message } = error?.error ?? {};
    this.title = 'Attenzione!';
    this.message = `Si è verificato un errore.`;

    if (message) {
      this.message += `<br>${message}`;
    }

    if (code) {
      this.message += `<br>Codice: ${code}`;
    }

    this._dialog?.showModal();
  }
}
