import { CanActivateFn, Router } from '@angular/router';
import { status$ } from '../../shared/status';
import { map } from 'rxjs';
import { Pages } from '../app.routes';
import { inject } from '@angular/core';
import { isWebview } from '../../shared/system';
import { environment } from '../../environments/environment';
import { EnvType } from '../../shared/enums/env-type';

export const webViewGuard: CanActivateFn = (route, state) => {

  if (isWebview()) {
    return true;
  }

  const { appURL, type } = environment;

  switch (type) {
    case EnvType.LOCALHOST:
    case EnvType.DEV:
      return true;
    case EnvType.PROD:
    case EnvType.STAGING: {
      const url = `${appURL}`;
      const a = document.createElement('a');
      a.href = url
      a.click();

      return false;
    }
  }
}

export const webViewUserGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return status$
    .pipe(
      map(status => {
        const { user } = status ?? {};
        // Se non c'è l'utente torna in home
        if (!user) {

          if (isWebview()) {
            return router.createUrlTree([Pages.home]);
          }

          const { appURL, type } = environment;

          switch (type) {
            case EnvType.LOCALHOST:
            case EnvType.DEV:
              return true;
            case EnvType.PROD:
            case EnvType.STAGING: {
              const url = `${appURL}`;
              const a = document.createElement('a');
              a.href = url
              a.click();

              return false;
            }
          }
        }

        return true
      })
    )
};

