import { Injectable } from '@angular/core';
import { StorageErrorType } from '../../shared/errors/errors.const';
import { StorageKey } from '../../shared/enums/storage.key';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  enabled = true;

  private _storage: Storage | null = null;

  constructor() {
    this.init()
  }

  init() {
    this.createStorage();
  }

  clear() {
    this._storage?.clear();
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: StorageKey | string, value: any) {
    if (!this.enabled) {
      throw Error(StorageErrorType.DISABLED);
    }

    this.createStorage();
    const valueString = JSON.stringify(value);
    return this._storage?.setItem(key, valueString);
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public get<T>(key: StorageKey | string): T | null {
    if (!this.enabled) {
      throw Error(StorageErrorType.DISABLED);
    }

    this.createStorage();
    const dataString = this._storage?.getItem(key)

    if (dataString) {
      return JSON.parse(dataString) as T
    }

    return null;

  }

  public remove(key: string) {
    if (!this.enabled) {
      throw Error(StorageErrorType.DISABLED);
    }

    this.createStorage();
    return this._storage?.removeItem(key)
  }

  private createStorage() {
    if (!!this._storage) {
      return;
    }

    if (!navigator?.cookieEnabled) {
      // Controllo per le modalità in incognito
      return;
    }
    if (!this._storage) {
      try {
        this._storage = window.localStorage

      } catch (e) {
        console.warn(`StorageService.createStorage`, e);
      }
    }
  }

  private async onError(error: any) {
    console.log(`StorageService.onError`, error);
  }
}
