import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { Pages } from '../app.routes';
import { AuthService } from '../services/auth.service';
import { getAndroidVersionNumber, getMobileOperatingSystem } from '../../shared/system';
import { environment } from '../../environments/environment';
import { AlertService } from '../services/alert.service';
import { status$ } from '../../shared/status';
import { ContestState } from '../../types/signup';

export const concorsoGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const auth = inject(AuthService);
  const alertService = inject(AlertService);
  const router = inject(Router);

  let isWebView = true;
  let token = auth.getAuthorizationToken();

  const { token: routeToken } = state.root.queryParams;

  if (routeToken) {
    auth.setAuthorizationToken(routeToken);
    token = routeToken;
    isWebView = false;
  }

  console.log(`.concorsoGuard - token`, token);

  const getRouteMediaDaCaricare = () => {
    // controllare se sono su Android nella webview, nel caso aprire su browser
    const os = getMobileOperatingSystem();
    if (isWebView && os === 'Android') {
      const androidVersion = getAndroidVersionNumber();

      if (androidVersion >= 13) {
        const { host } = environment;
        const url = `${host}/carica?openOutsideMcd=true&token=${token}`;
        const a = document.createElement('a');
        a.href = url
        // a.target = '_blank';
        a.click();

        // window.open(url);
        return router.createUrlTree([Pages.home]);
      }

      return true;
    }

    return true;
  }

  const getFromStato = (stato: ContestState) => {
    switch (stato) {
      // Se ho un contenuto da caricare va avanti alla pagina di concorso
      case 'media_da_caricare': {
        return getRouteMediaDaCaricare();
      }
      case 'flusso_terminato':
        // Se ho già caricato il contenuto resta in home e mostra un messaggio?
        return router.createUrlTree([Pages.complimenti]);
      case 'concorso_terminato':
        // Concorso terminato
        return router.createUrlTree([Pages.complimenti]);
      default:
        // Se è uno stato diverso lo mando alla registrazione?
        console.log(`concorsoGuard - user/me - stato: `, stato);
        return router.createUrlTree([Pages.registrazione]);
    }
  }

  return userService.user$
    .pipe(
      map(user => {
        console.log(`userGuard.user.status`, user?.status)

        if (!isWebView) {
          return true
        }

        switch (user.status) {
          case "none": {
            return router.createUrlTree([Pages.home])
          }
          case "request": {
            return router.createUrlTree([Pages.home])
          }
          case "success": {
            if (!token) {
              return router.createUrlTree([Pages.tutorial])
            }
            return true
          }
          case "error": {
            return router.createUrlTree([Pages.home])
          }
          default: {
            return router.createUrlTree([Pages.home])
          }
        }
      }),
      switchMap(result => {
        // Se ho un token verifico lo stato dell'utente
        if (typeof result === 'boolean' && result === true) {

          // Se ho già completato il flusso è inutile fare altre chiamate
          const status = status$.getValue();
          const { stato } = status?.contest ?? {};
          if (stato === 'flusso_terminato') {
            return of(router.createUrlTree([Pages.complimenti]));
          }

          if (stato === 'media_da_caricare') {
            return of(getRouteMediaDaCaricare());
          }

          return auth.me()
            .pipe(
              map(response => {

                const { game, user, contest: { stato } } = response.data;

               return getFromStato(stato);

              }),
              catchError(e => {
                // Se c'è un errore probabilmente il token non è valido quindi si deve registrare
                auth.removeAuthorizationToken();
                alertService.error(e);
                return of(router.createUrlTree([Pages.home]));
              })
            )
        }

        // Se non ho i dati dell'utente lo rimando in home
        return of(result);
      })
    );
}
